import React from 'react';
import MyCompany from 'fetures/MyCompany';
import PageTitle from 'components/PageTitle';

const MyCompaniesPage = () => {
  return (
    <>
      <PageTitle title="My Companies" />

      <MyCompany />
    </>
  );
};

export default MyCompaniesPage;
