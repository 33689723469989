/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ErrorMessage, Field, FieldArray, Form, Formik, useFormik, useFormikContext } from 'formik';
// eslint-disable-next-line import/no-unresolved
import { Autoplay, Mousewheel, Pagination, Scrollbar } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import { BorderButton, InputBox } from 'components';
import TextAreaInput from 'components/TextAreaInput';
import { getLoadingMessages } from 'api/config';
import {
  getAreas,
  getAttributes,
  getMindMampFileContentApi,
  getWebsite,
  getWebsiteResponse,
  mindMampApi,
} from 'api/mindMamp';
import { catchBlock } from 'utils';
import { DEFAULT_COMPANY_PROFILE, routes, TOAST_CONFIG } from '../../../../constant';
import { MindMampInitialValues, MindMampValidation } from './constant';

import './style.css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// import 'swiper/css/bundle';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'swiper/swiper-bundle.css';

const FormComponent = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [websiteRes, setWebsiteRes] = useState();
  const [fetchinWeb, setFetchinWeb] = useState(false);
  const [formInitValues, setFormInitValues] = useState(MindMampInitialValues);
  const [areas, setAreas] = useState([]);
  const [defultArea, setDefultArea] = useState(0);
  const [attributes, setAttributes] = useState([]);
  const [topics, setTopics] = useState([
    'Research SEO Keywords',
    'Develop ideal customer profile',
    'Optimise marketing campaigns',
  ]);
  const [singleTopic, setSingleTopic] = useState(0);
  const [loadingMessages, setLoadingMessages] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState(null);

  const { isFetching: isFetchingLoadingMessages } = useQuery(['get-loading-messages'], () => getLoadingMessages(), {
    refetchOnMount: 'always',
    onSuccess: (response) => {
      setLoadingMessages(response?.messages || null);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: addWorkSpace, isLoading } = useMutation(mindMampApi, {
    onSuccess: (res) => {
      toast.success(res.message, TOAST_CONFIG);
      setTimeout(() => {
        setLoaderMessage(null);
        navigate(routes.workspaceEdit.replace(':id', res.data.id), { replace: true });
      }, 800);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: getAttributesApi, isLoading: isLoadingAttributes } = useMutation(getAttributes, {
    onSuccess: (res) => {
      const formAttributes = res?.attributes?.map((attribute, index) => {
        if (attribute?.values?.length > 0 && attribute?.values[0] === '*')
          return { ...attribute, subAttributes: [{ name: 1 }], nextSubAttributes: 2 };
        return { ...attribute };
      });

      setAttributes(formAttributes || []);
      setTopics(res?.topics || []);
      setSingleTopic(0);
      const nameObj = {};
      res?.attributes?.forEach((element) => {
        nameObj[element?.name] = '';
      });
      const allData = Object.keys({ ...formRef?.current?.values });
      allData?.map((x) => {
        nameObj[x] = MindMampInitialValues[x];
      });
      setFormInitValues(nameObj);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: getAreasApi, isLoading: isLoadingArea } = useMutation(getAreas, {
    onSuccess: async (res) => {
      const sortedArea = res?.areas.sort((a, b) => {
        return Number(a.index) >= Number(b.index) ? 1 : -1;
      });

      const areasOption = await sortedArea.reduce((acc, area) => {
        acc.push({
          label: area.name,
          value: acc.length,
          index: area.index,
          isParent: true,
        });

        if (area.subareas) {
          area.subareas.forEach((subarea) => {
            acc.push({
              label: subarea.name,
              value: acc.length,
              index: subarea.index,
            });
          });
        }

        return acc;
      }, []);

      setAreas(areasOption || []);
      const selectedRec = areasOption.find((rec) => rec.label === 'Sales and Marketing');

      setDefultArea(selectedRec?.value || '0');
      getAttributesApi({ area_index: selectedRec?.index || '0' });
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const getWebsiteApi = useMutation(getWebsite);

  const { mutate: getWebsiteresApi } = useMutation(getWebsiteResponse, {
    onSuccess: (res) => {
      setWebsiteRes(res?.websiteInfo);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const HandleSubmitForm = async (values) => {
    try {
      setLoaderMessage(loadingMessages?.workspace_create_1 || null);

      const findArea = areas.find((area) => area.value === defultArea);

      const outputVal = [
        { key: 1, isGroup: true, category: 'treeView', name: 'mindmap', data: { title: 'Mindmap' } },
        {
          key: 2,
          isGroup: true,
          category: 'gridView',
          name: 'challenges',
          data: { title: 'Challenges' },
        },
        { key: 3, isGroup: true, category: 'gridView', name: 'insights', data: { title: 'Insights' } },
        {
          key: 4,
          isGroup: true,
          category: 'gridView',
          name: 'kpis',
          data: { title: 'Key Performance Indicators (KPIs)' },
        },
        {
          key: 5,
          group: 1,
          category: 'treeViewInitial',
          name: 'mindmap',
          data: { title: values.prompt },
        },
        {
          key: 6,
          group: 3,
          category: 'gridViewInitial',
          name: 'insights',
          data: { title: 'New Insight' },
        },
        {
          key: 7,
          group: 4,
          category: 'gridViewInitial',
          name: 'kpis',
          data: { title: 'New KPI' },
        },
        {
          key: 8,
          group: 2,
          category: 'gridViewInitial',
          name: 'challenges',
          data: { title: 'New Challenge' },
        },
      ];

      const companyProfile = localStorage.getItem(DEFAULT_COMPANY_PROFILE)
        ? JSON.parse(localStorage.getItem(DEFAULT_COMPANY_PROFILE))
        : null;

      const request = {
        title: values.prompt,
        output: JSON.stringify(outputVal).replaceAll('\\\\', ''),
        area: findArea?.label,
        description: websiteRes,
        website: values.description ? values.description : null,
        companyProfile: companyProfile ? companyProfile.value : null,
      };
      // if (values?.description) {
      //   setFetchinWeb(true);
      //   await getWebsiteApi.mutateAsync(
      //     { url: values?.description },
      //     {
      //       onSuccess: (res) => {
      //         request.description = res?.websiteInfo;
      //         request.attributes = { website: res?.websiteInfo };
      //         setFetchinWeb(false);
      //       },
      //       onError: (error) => {
      //         setFetchinWeb(false);
      //         catchBlock(error);
      //       },
      //     },
      //   );
      // }

      // const request = {
      //   prompt: values.prompt,
      //   generation_type: '',
      //   context: '',
      //   area: findArea?.name,
      // };

      let attributesData = formRef?.current?.values?.attributes;

      if (formRef?.current?.values?.attributes) {
        const promises = Object.entries(formRef?.current?.values?.attributes).map(async ([key, value]) => {
          let newValue = value;

          if (Array.isArray(value)) {
            const valueArray = await Promise.all(
              value.map(async (subValue) => {
                if (subValue instanceof File) {
                  const formData = new FormData();
                  formData.append('file', subValue);
                  const res = await getMindMampFileContentApi(formData);
                  return res.data;
                }
                return subValue;
              }),
            );

            newValue = valueArray.filter((v) => v !== undefined);
          }

          return [key, newValue];
        });

        await Promise.all(promises).then((newEntries) => {
          attributesData = Object.fromEntries(newEntries);
        });
      }

      addWorkSpace({ ...request, ...{ attributes: attributesData } });
      // const response = await getWebsiteresApi(request);
      // console.log(values, request, formRef?.current?.values);
      // return response;
    } catch (error) {
      // toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
      catchBlock(error);
      setLoaderMessage(null);
    }
  };

  useEffect(() => {
    if (getAreasApi) getAreasApi();
  }, [getAreasApi]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSingleTopic((prevSingleTopic) => prevSingleTopic + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, [topics]);

  useEffect(() => {
    if (topics.length < singleTopic) {
      setSingleTopic(0);
    }
  }, [topics, singleTopic]);

  const onCHangeHandler = (eve) => {
    const selectedRec = areas.find((rec) => rec.value === eve.value);
    if (selectedRec) {
      setDefultArea(selectedRec.value);
      getAttributesApi({ area_index: selectedRec.index });
    }
  };

  const clickedTopic = (ele) => {
    // ele.currentTarget.innerText
    formRef?.current?.setFieldValue('prompt', ele.currentTarget.innerText);
    console.log('clickedTopic', ele.currentTarget);
  };

  return (
    <>
      {isLoadingArea === true ||
      isLoadingAttributes === true ||
      fetchinWeb === true ||
      isLoading === true ||
      isFetchingLoadingMessages === true ? (
        <div className="spinner-div-overlay">
          <Spinner animation="border" className="spiner-mind-map" />
          {loaderMessage && (
            <div className="spiner-message">
              {loaderMessage.split('\n').map((msg, index) => (
                <p key={index}>{msg}</p>
              ))}
            </div>
          )}
        </div>
      ) : (
        ''
      )}

      <div className="containers mm_container">
        <Formik
          initialValues={MindMampInitialValues}
          validationSchema={MindMampValidation}
          onSubmit={HandleSubmitForm}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, errors, touched, handleSubmit, isSubmitting, handleChange, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-container">
                <p className="form_first_title">- LET 'S GET YOU STARTED</p>
                <div className="home_container_border">
                  <div className="img-cntnr">
                    <img className="home_img" src="/assets/images/goal.svg" alt="home" />
                  </div>
                  <div className="form-container mm_creation_frm">
                    <div className="form-propmpt-input-div">
                      <h4 className="h2-tag">What is your objective?</h4>
                      <Field
                        // placeholder="Ex. Increase website visitors conversion"
                        placeholder={topics[singleTopic]}
                        name="prompt"
                        onChange={handleChange}
                        component={InputBox}
                        type="text"
                        value={values?.prompt}
                        customClass="orange_glow"
                      />
                      {/* {errors.prompt && touched.prompt ? <div className="form-error-msg">{errors.prompt}</div> : null} */}
                    </div>
                  </div>
                  <div className="form-container mm_creation_frm">
                    <div className="brain-area">
                      <h4 className="h2-tag">Subject area</h4>
                      {/* <select
                        className="d-block justify-content-center fs-7 inputBoxStyle orange_glow cursor_pointer"
                        onChange={onCHangeHandler}
                        value={defultArea}
                      >
                        {areas?.map((row, i) => (
                          <option value={row?.index} key={row?.index}>
                            {row?.name}
                          </option>
                        ))}
                        <option>Sales and Marketing</option>
                      </select> */}
                      <Select
                        options={areas}
                        value={areas?.find((a) => a.value == defultArea) || null}
                        components={{
                          // eslint-disable-next-line react/no-unstable-nested-components
                          Option: (props) => (
                            <components.Option
                              // eslint-disable-next-line react/prop-types
                              className={`${props?.data?.isParent ? 'parent-option' : 'child-option'}`}
                              {...props}
                            />
                          ),
                        }}
                        onChange={onCHangeHandler}
                        className="rc-select area-select"
                        classNamePrefix="rc-select"
                        placeholder="Select Area"
                      />
                    </div>
                  </div>
                  <div className="form-container mm_creation_frm">
                    <div className="brain-area">
                      <button type="submit" className="navbar-login-button">
                        Start
                      </button>
                      <div className="customisation-tag">
                        <h4 className="h2-tag">{attributes?.length || 0}</h4>
                        <span>Customisations available</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <p className="form_first_title form_second_title">- OBJECTIVES YOU MIGHT FIND USEFUL</p> */}
              </div>
              <div className="right-slider-mindmap">
                <p className="form_first_title margin_left">- CUSTOMISATIONS (OPTIONAL)</p>
                <Swiper
                  className="mySwiper"
                  slidesPerView={3}
                  centeredSlides
                  initialSlide={1}
                  direction="vertical"
                  mousewheel
                  modules={[Mousewheel, Autoplay, Pagination, Scrollbar]}
                  noSwipingClass="swiper-no-swiping"
                  scrollbar={{ draggable: true }}
                >
                  {attributes?.map((attribute, attributeIndex) => {
                    return attribute?.values.length > 0 && attribute?.values[0] === '*' ? (
                      attribute.subAttributes.map((subAttribute, subAttributeIndex) => (
                        <SwiperSlide key={`${attributeIndex + 1}-${subAttributeIndex + 1}`}>
                          <h5 className="orange_text">
                            {values?.attributes &&
                            values.attributes[attribute.name] &&
                            Object.prototype.hasOwnProperty.call(
                              values.attributes[attribute.name],
                              subAttribute?.name,
                            ) &&
                            values?.attributes[attribute.name][subAttribute?.name] !== null
                              ? 'File uploaded'
                              : attribute?.name}
                          </h5>

                          <label
                            htmlFor={`file-attributes-${attributeIndex + 1}-${subAttributeIndex + 1}`}
                            className="d-block justify-content-center fs-7 my-3 inputBoxStyle orange_glow_file"
                          >
                            {values?.attributes &&
                            values.attributes[attribute.name] &&
                            Object.prototype.hasOwnProperty.call(
                              values.attributes[attribute.name],
                              subAttribute?.name,
                            ) &&
                            values?.attributes[attribute.name][subAttribute?.name] !== null ? (
                              <p className="active">
                                {values?.attributes[attribute.name][subAttribute?.name]?.name}
                                <span
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setFieldValue(`attributes[${attribute?.name}][${subAttribute?.name}]`, null);

                                    if (attribute?.subAttributes?.length > 1) {
                                      const newAttributes = [...attributes];
                                      // eslint-disable-next-line no-unsafe-optional-chaining
                                      const newSubAttributes = [...attribute?.subAttributes];
                                      newSubAttributes.splice(subAttributeIndex, 1);

                                      newAttributes[attributeIndex] = { ...attribute, subAttributes: newSubAttributes };
                                      setAttributes([...newAttributes]);
                                    }
                                  }}
                                >
                                  X
                                </span>
                              </p>
                            ) : (
                              <p>Click to upload</p>
                            )}
                            <input
                              id={`file-attributes-${attributeIndex + 1}-${subAttributeIndex + 1}`}
                              type="file"
                              name={`attributes[${attribute?.name}][${subAttribute?.name}]`}
                              accept=".doc, .docx, .txt, .rtf, .pdf, .pages"
                              placeholder="Choose File"
                              onChange={(event) => {
                                if (
                                  ['doc', 'docx', 'txt', 'rtf', 'pdf', 'pages'].includes(
                                    event.currentTarget.files[0].name.split('.').pop(),
                                  )
                                ) {
                                  const isFileUploaded = !!(
                                    values?.attributes &&
                                    values?.attributes[attribute.name] &&
                                    values?.attributes[attribute.name][subAttribute?.name]
                                  );

                                  setFieldValue(
                                    `attributes[${attribute?.name}][${subAttribute?.name}]`,
                                    event.currentTarget.files[0],
                                  );

                                  if (!isFileUploaded) {
                                    const newAttributes = [...attributes];
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    const newSubAttributes = [...attribute?.subAttributes];

                                    const newName = attribute?.nextSubAttributes;

                                    newSubAttributes.splice(subAttributeIndex + 1, 0, {
                                      name: newName,
                                    });

                                    newAttributes[attributeIndex] = {
                                      ...attribute,
                                      subAttributes: newSubAttributes,
                                      nextSubAttributes: newName + 1,
                                    };
                                    setAttributes([...newAttributes]);
                                  }
                                } else toast.error('Please select valid file type.', TOAST_CONFIG);
                              }}
                            />
                          </label>
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide key={attributeIndex + 1}>
                        <h5 className="orange_text">{attribute?.name}</h5>

                        {attribute?.values.length > 0 ? (
                          <div className="swiper-no-swiping">
                            <Select
                              name={`attributes[${attribute?.name}]`}
                              options={
                                attribute?.values?.reduce((attributes, attribute) => {
                                  attributes.push({
                                    label: attribute,
                                    value: attribute === '-' ? null : attribute,
                                  });

                                  return attributes;
                                }, []) || []
                              }
                              className="rc-select customisations-select"
                              classNamePrefix="rc-select"
                              onChange={(option) =>
                                setFieldValue(`attributes[${attribute?.name}]`, option ? option.value : null)
                              }
                              value={
                                values?.attributes?.[`${attribute?.name}`]
                                  ? {
                                      label: values?.attributes[`${attribute?.name}`],
                                      value: values?.attributes[`${attribute?.name}`],
                                    }
                                  : null
                              }
                              placeholder="Click to select"
                              menuPortalTarget={document.body}
                              // defaultValue={{ label: '-', value: '' }}
                            />
                          </div>
                        ) : (
                          (attribute?.name === 'Website' && (
                            <>
                              <input
                                type="text"
                                // component={InputBox}
                                name="description"
                                onChange={handleChange}
                                placeholder="Type your URL"
                                className="d-block justify-content-center fs-7 my-3 inputBoxStyle orange_glow_input"
                              />
                              {errors.description && touched.description ? (
                                <div className="form-input-error-msg text-right">{errors.description}</div>
                              ) : null}
                            </>
                          )) || (
                            <Field
                              type="text"
                              component={InputBox}
                              placeholder="Type your..."
                              className="d-block justify-content-center fs-7 my-3 inputBoxStyle orange_glow_input"
                              name={`attributes[${attribute?.name}]`}
                            />
                          )
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default FormComponent;
