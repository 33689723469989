import React, { useState } from 'react';
import { Form, Nav, NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { AiOutlineMenu } from 'react-icons/ai';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, routes, USER_DATA_LOCAL_STORAGE_KEY } from 'constant';
import Footer from 'components/Footer';
import MobileRestrictionModal from 'components/TopNavigationBar/MobileRestrictionModal';

import './style.css';

const ColorSchemesExample = () => {
  const navigate = useNavigate();
  const [mobileRestrictionModalClass, setMobileRestrictionModalClass] = useState('d-none');

  const location = useLocation();

  const loginUser = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  let isLogin = false;
  if (loginUser !== null) {
    isLogin = true;
  }
  const userData = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));

  const [option] = useState(userData?.name);

  return (
    <div className="darkBlack">
      <Navbar expand="lg" className="navbar-bg-class navigation-bar-cont">
        {/* <Container> */}
        <Navbar.Brand>
          <Link to={routes.home} className="titleLogoTextHomePage text-decoration-none">
            Ideon<span>AI</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-togglebar-icon ">
          {' '}
          <AiOutlineMenu color="#fff" size="23" fontWeight="bold" className="modal-attachment-plus-icon" />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="custom-dropdown">
          <Nav className="me-auto home_layout_margin_left">
            <div className="hover-class">
              <Link to={routes.home} className={location.pathname === routes.home ? 'active-link' : 'navbar-navs-link'}>
                Home
              </Link>
            </div>
            <div className="navbar-dropdown-toggle">
              <NavDropdown title="Solutions" id="collasible-nav-dropdown" className="nav-dropdown">
                {/* <NavDropdown.Item
                  className="navbar-select-box-item "
                  onClick={() => {
                    navigate(routes.marketingHome);
                  }}
                >
                  Marketing
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="navbar-select-box-item "
                  onClick={() => {
                    navigate(routes.tech);
                  }}
                >
                  Tech
                </NavDropdown.Item> */}

                <NavDropdown.Item
                  className="navbar-select-box-item "
                  onClick={() => {
                    navigate(routes.consulting);
                  }}
                >
                  Consulting
                </NavDropdown.Item>
              </NavDropdown>
            </div>
            <div className="hover-class">
              <Link
                to={routes.homeAbout}
                className={location.pathname === routes.homeAbout ? 'active-link' : 'navbar-navs-link'}
              >
                About us
              </Link>
            </div>{' '}
            <div className="hover-class">
              <Link
                to={routes.homeContact}
                className={location.pathname === routes.homeContact ? 'active-link' : 'navbar-navs-link'}
              >
                Contact
              </Link>
            </div>{' '}
            <div className="hover-class">
              <Link
                to={routes.stripeEmbedDemo}
                className={location.pathname === routes.stripeEmbedDemo ? 'active-link' : 'navbar-navs-link'}
              >
                Pricing
              </Link>
            </div>
          </Nav>
          {isLogin ? (
            <div className="navbar-dropdown">
              <NavDropdown title={option} id="collasible-nav-dropdown" className="navbar-select-box">
                <NavDropdown.Item
                  className="navbar-select-box-item"
                  onClick={() => {
                    navigate(routes.dashBoard);
                  }}
                >
                  Workspaces
                </NavDropdown.Item>

                <NavDropdown.Item
                  className="navbar-select-box-item"
                  onClick={() => {
                    navigate(routes.myAccount);
                  }}
                >
                  My Account
                </NavDropdown.Item>

                <NavDropdown.Item
                  className="navbar-select-box-item"
                  onClick={() => {
                    navigate(routes.myCompanies);
                  }}
                >
                  My Companies
                </NavDropdown.Item>

                <NavDropdown.Item
                  className="navbar-select-box-item"
                  onClick={() => {
                    localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
                    navigate(routes.login);
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          ) : (
            <div className="navbar-login-div">
              <button
                type="button"
                className="navbar-login-link navbar-login-button"
                onClick={() => setMobileRestrictionModalClass('d-block')}
              >
                Login
              </button>
              <Link to={routes.login} className="navbar-login-link navbar-login-button">
                Log in
              </Link>
              <Link to={routes.register} className="navbar-login-link navbar-login-button">
                Register
              </Link>
              {/* </button> */}
            </div>
          )}
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
      <Outlet />
      <Footer />
      <MobileRestrictionModal wrapperClass={mobileRestrictionModalClass} />
    </div>
  );
};

export default ColorSchemesExample;
