/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FiPlus } from 'react-icons/fi';
import { RiCheckFill, RiCloseLine } from 'react-icons/ri';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getMindmapActionPlan, getMindmapDetails, updateMindmapDetails } from 'api/mindMamp';
import ActionPlanCard from '../ActionPlanCard';

import './style.css';

const Evaluate = ({ nodeMoreActionsData, goDiagram }) => {
  const actionPlanTitleInput = useRef(null);
  const actionPlanDescriptionInput = useRef(null);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isActionPlanInEditMode, setIsActionPlanInEditMode] = useState(false);
  const [actionPlans, setActionPlans] = useState([]);

  const { mutate: updateMindmapDetailsApi, isLoading: updateMindmapDetailsApiLoading } = useMutation(
    updateMindmapDetails,
    {
      onSuccess: (res) => {
        return;
      },
      onError: (error) => {
        catchBlock(error);
      },
    },
  );

  const { mutate: getMindmapActionPlanApi, isLoading: getMindmapActionPlanApiLoading } = useMutation(
    getMindmapActionPlan,
    {
      onSuccess: (res) => {
        if (res?.data && res?.data?.length > 0) {
          setActionPlans(res?.data || []);
          var type = 'evaluate';

          if (nodeMoreActionsData?.action === 'Risk') {
            type = 'risks';
          } else if (nodeMoreActionsData?.action === 'Examples') {
            type = 'examples';
          }

          updateMindmapDetailsApi({
            workspace_id: nodeMoreActionsData?.workspace_id,
            node_id: nodeMoreActionsData?.node_id,
            actionPlan: res?.data || [],
            type,
          });
        }
      },
      onError: (error) => {
        catchBlock(error);
      },
    },
  );

  const { mutate: getMindmapDetailsApi, isLoading: getMindmapDetailsApiLoading } = useMutation(getMindmapDetails, {
    onSuccess: (res) => {
      if (res?.data && res?.data !== null) {
        setActionPlans(res?.data?.evaluation || []);
      } else if (goDiagram.current && nodeMoreActionsData?.node_id) {
        const diagram = goDiagram.current.getDiagram();
        const node = diagram.findNodeForKey(nodeMoreActionsData?.node_id);

        const context = [];

        for (let n = node; n !== null; n = n.findTreeParentNode()) {
          context.push({
            main: n.data.data.title,
            body: n.data.data.description,
          });
        }

        const prompt = context[0] || {};

        context.reverse();
        context.splice(-1);

        var type = 'evaluate';
        if (nodeMoreActionsData?.action === 'Risk') {
          type = 'risks';
        } else if (nodeMoreActionsData?.action === 'Examples') {
          type = 'examples';
        }

        getMindmapActionPlanApi({
          prompt,
          context,
          workspace_id: nodeMoreActionsData?.workspace_id,
          type,
        });
      }
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const handleChangeEditMode = async () => {
    if (isActionPlanInEditMode) {
      await updateMindmapDetailsApi({
        workspace_id: nodeMoreActionsData?.workspace_id,
        node_id: nodeMoreActionsData?.node_id,
        actionPlan: actionPlans || [],
        type: 'evaluate',
      });
    }

    setIsActionPlanInEditMode(!isActionPlanInEditMode);
  };

  const handleDeleteActionPlan = (index) => {
    setActionPlans((prevActionPlans) => prevActionPlans.filter((_, i) => i !== index));
  };

  const handleOnActionPlanDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setActionPlans((prevActionPlans) => {
      const newActionPlans = [...prevActionPlans];
      const [movedItem] = newActionPlans.splice(sourceIndex, 1);
      newActionPlans.splice(destinationIndex, 0, movedItem);
      return newActionPlans;
    });
  };

  const handleActionPlanCreationAction = async (action) => {
    if (action === 'cancel') {
      if (actionPlanTitleInput.current) actionPlanTitleInput.current.value = '';
    } else if (action === 'save' && actionPlanTitleInput.current && actionPlanTitleInput.current.value !== '') {
      const actionPlanTitle = actionPlanTitleInput.current.value;
      const actionPlanDescription = actionPlanDescriptionInput.current.value;

      const newActionPlans = [
        ...actionPlans,
        {
          main: actionPlanTitle || '',
          body: [
            {
              heading: 'Description',
              content: actionPlanDescription || '',
            },
          ],
        },
      ];

      setActionPlans(newActionPlans);

      await updateMindmapDetailsApi({
        workspace_id: nodeMoreActionsData?.workspace_id,
        node_id: nodeMoreActionsData?.node_id,
        actionPlan: newActionPlans || [],
        type: 'evaluate',
      });
    }

    setIsCreateModalOpen(false);
  };

  const handleUpdateActionPlan = async (index, data) => {
    const newActionPlans = [...actionPlans];
    newActionPlans[index] = data;
    setActionPlans(newActionPlans);

    await updateMindmapDetailsApi({
      workspace_id: nodeMoreActionsData?.workspace_id,
      node_id: nodeMoreActionsData?.node_id,
      actionPlan: newActionPlans || [],
      type: 'evaluate',
    });
    return;
  };

  useEffect(() => {
    if (getMindmapDetailsApi) {
      var type = 'evaluate';
      if (nodeMoreActionsData?.action === 'Risk') {
        type = 'risks';
      } else if (nodeMoreActionsData?.action === 'Examples') {
        type = 'examples';
      }

      getMindmapDetailsApi({
        workspace_id: nodeMoreActionsData?.workspace_id,
        node_id: nodeMoreActionsData?.node_id,
        type,
      });
    }
  }, [getMindmapDetailsApi, nodeMoreActionsData]);

  return (
    <div className="maap-action-plan-wrapper">
      {(getMindmapDetailsApiLoading || getMindmapActionPlanApiLoading || updateMindmapDetailsApiLoading) && (
        <div className="maap-action-plan-spinner-wrapper">
          <Spinner animation="border" className="maap-action-plan-spinner" />
        </div>
      )}

      <h4>
        {nodeMoreActionsData?.action === 'Risk'
          ? 'Risk'
          : nodeMoreActionsData?.action === 'Examples'
            ? 'Examples'
            : 'Evaluation (1-10)'}
        <span onClick={handleChangeEditMode}>{isActionPlanInEditMode ? 'Done' : 'Edit'}</span>
      </h4>

      <DragDropContext onDragEnd={handleOnActionPlanDragEnd}>
        <Droppable droppableId="actionPlanList">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="maap-action-plan-list">
              {provided.placeholder}

              {actionPlans &&
                actionPlans.map((actionPlan, index) => (
                  <Draggable key={`actionPlan-${index}`} draggableId={`actionPlan-${index}`} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <ActionPlanCard
                          index={index + 1}
                          actionPlan={actionPlan}
                          isActionPlanInEditMode={isActionPlanInEditMode}
                          handleDeleteActionPlan={handleDeleteActionPlan}
                          dragHandleProps={provided.dragHandleProps}
                          handleUpdateActionPlan={handleUpdateActionPlan}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

              <div className={`maap-action-plan-creation-card ${isActionPlanInEditMode && 'edit-mode'}`}>
                {isCreateModalOpen ? (
                  <div className="maap-action-plan-creation-form">
                    <div className="maap-action-plan-creation-form-header">
                      <p>{(actionPlans.length || 0) + 1}.</p>

                      <input
                        ref={actionPlanTitleInput}
                        className="maap-action-plan-title-input"
                        type="text"
                        placeholder="Enter Title"
                      />

                      <div className="maap-action-plan-creation-form-action-button">
                        <RiCheckFill onClick={() => handleActionPlanCreationAction('save')} color="#f05436" size="22" />
                        <RiCloseLine
                          onClick={() => handleActionPlanCreationAction('cancel')}
                          color="#f05436"
                          size="22"
                        />
                      </div>
                    </div>

                    <div className="maap-action-plan-creation-form-description">
                      <input
                        ref={actionPlanDescriptionInput}
                        className="maap-action-plan-description-input"
                        type="text"
                        placeholder="Enter Description"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="maap-action-plan-creation-add-button" onClick={() => setIsCreateModalOpen(true)}>
                    <FiPlus color="#f05436" size="22" />
                    <p>Add</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

Evaluate.defaultProps = {
  nodeMoreActionsData: PropTypes.object,
  goDiagram: PropTypes.any,
};

Evaluate.propTypes = {
  nodeMoreActionsData: PropTypes.object,
  goDiagram: PropTypes.any,
};
export default Evaluate;
