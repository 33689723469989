/* eslint-disable simple-import-sort/imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import { routes, USER_DATA_LOCAL_STORAGE_KEY } from 'constant';
import { InviteForm } from 'fetures/MyAccount/Components';
import { TopNavigationBar } from 'components';
import { fetchAllSubscriptions } from 'api/payment';
import { catchBlock } from 'utils';

import './style.css';

const MyAccount = () => {
  const userData = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));

  const [slots, setSlots] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);

  const { mutate: mutateAllSubscriptions, isFetching: isLoadingSubscriptions } = useMutation(fetchAllSubscriptions, {
    onSuccess: async (data) => {
      setSlots(data?.qty && data?.qty > 0 ? Array.from(Array(data.qty - 1), (_, i) => i + 1) : []);
      setSubAccounts(data?.subAccounts || []);
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const refetch = () => {
    setSlots([]);
    mutateAllSubscriptions();
  };

  useEffect(() => {
    mutateAllSubscriptions();
  }, []);

  return (
    <>
      {isLoadingSubscriptions && (
        <div className="myaccount_page-spinner-wrapper">
          <Spinner animation="border" className="myaccount_page-spiner" />
        </div>
      )}

      <div className="myaccount_page-wrapper">
        <TopNavigationBar isShowCompanyDropdown={false} logoLink={routes.home} />

        <div className="myaccount_page-title-wrapper">
          <h3 className="myaccount_page-title">My Account</h3>
        </div>

        <div className="myaccount_page-container m-auto">
          {userData?.is_free_user === false && userData?.is_sub_user === false && (
            <div>
              <h2 className="workspace-modal-title">Subscription and billing</h2>

              <button
                type="button"
                className="m-0 workspace-modal-button sub_button"
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_STRIPE_ACCOUNT_LINK}?prefilled_email=${userData?.email}`;
                }}
              >
                Manage Subscription
              </button>
            </div>
          )}

          {slots && slots.length > 0 && (
            <div>
              <div className="container text-white p-0">
                <h2 className="workspace-modal-title">Delegated Access</h2>
              </div>
              <div className="container text-white">
                <div className="row">
                  {slots.map((n, i) => (
                    <InviteForm n={n} account={subAccounts?.[i]} refetchSlots={refetch} key={n} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyAccount;
